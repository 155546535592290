<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Header class="resourceheader"/>
    <div class="container-fluid maincontentcontainer" v-if="post">     
  <div class="container" style="text-align:left;">
    <div class="col-xs-12 col-sm-3 pull-left" id="sidebar">
      
      <div class="blogpicture">
        <img class="img-responsive" :src="baseURL + blogImagePath + postImage">
      </div>
      
      <h2>Recent Posts</h2>
      <div class="recent-posts">
          <a class="recent-post" v-for="(post, index) in recentPosts" :key="index" @click="showDetail(post)" >{{ post.title }}</a>
      </div>
      <div @click="showAllBlogs()" class="readmorebutton" style="width: 100%;"> All Posts</div>
    </div>
    <div class="col-xs-12 col-sm-9 pull-right">
      <div class="col-xs-12 solid-border-blog-details">
        <h1>{{ post.title }}</h1>
      </div>
      <div class="col-xs-12"> 
        <div class="post-date-details">Posted by Jacinta Gallant on {{ postDate(post.date) }}</div>
        <p v-html="post.post"/>
        <br/><br/>
        <iframe v-if="post.ytLink" width="560" height="315" style="margin-bottom:30px;" :src="'https://www.youtube.com/embed/'+post.ytLink" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>

        <div v-if="post.scEmbed" v-html="post.scEmbed"></div>

        <div>
            <img style="width:25%;height:25%" class="extra-blogpicture-details blogpicture" v-for="(image, index) in images" :key="index" :src="baseURL + blogImagePath + image.filename" />
        </div>
      </div>
    </div>
  </div>
</div>
<Footer/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Components
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

// API services
import BlogServices from '../services/BlogServices.js'

// Primevue
//import { useToast } from 'primevue/usetoast'

export default {
  name: 'EventDetail',
  components: {
    Header,
    Footer
  },
  setup() {
    ///const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const baseURL = process.env.VUE_APP_API_SERVER
    const blogImagePath = process.env.VUE_APP_BLOG_IMAGES
    const post = ref()
    const postImage = ref()
    const recentPosts = ref()
    const images = ref([])

    if(route.query.blogID === undefined) { 
      router.push({name:"Blog"})
    }

    const showAllBlogs = () => {
      router.push({ name:"Blog" })
    }

    const showDetail = (recentPost) => {
      router.push({ name:"BlogDetail", query: {blogID: recentPost.id} })
      BlogServices.getBlog(recentPost.id)
      .then((response) => {
        post.value = response.data
      })

      BlogServices.getBlogImages(recentPost.id)
      .then((response) => {
        if(response.data.length >= 1  && response.status === 200) {
        images.value = response.data
        postImage.value = images.value[0].filename
        console.log(postImage.value)
        images.value.splice(0,1)
        } else {
          postImage.value = "blogpost5.png"
        }
      })
    }

  onMounted(() => {
    BlogServices.getBlog(route.query.blogID)
    .then((response) => {
      post.value = response.data
    })

    BlogServices.getBlogImages(route.query.blogID)
    .then((response) => {
      if(response.data.length >= 1  && response.status === 200) {
        images.value = response.data
        postImage.value = images.value[0].filename
        console.log(postImage.value)
        images.value.splice(0,1)
      } else {
        postImage.value = "blogpost5.png"
      }
    })

    BlogServices.getBlogs()
      .then((response) => {
        recentPosts.value = response.data
      })
  })

  return {
    environment,
    baseURL,
    blogImagePath,
    post,
    postImage,
    images,
    recentPosts,
    showAllBlogs,
    showDetail
  }

  },
  
  methods: {
    postDate(value) {
      let date = new Date(value)
      date = date.toLocaleString("en-CA", {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
      })
      if(date == "Invalid Date") {
          date = "--"
      }
      return date
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
